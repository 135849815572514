import React from "react"
import { graphql } from "gatsby"
import Layout from "@components/Layout"
import { FiArrowUpRight } from "react-icons/fi"
import Seo from "@components/Seo"

const ContactPage = ({ data }) => {
  const { pageMeta } = data

  return (
    <Layout>
      <Seo yoastSeo={pageMeta?.seo} />

      <section className="bg-slate-100 dark:bg-slate-800 py-20">
        <div className="container--boxed">
          <h1 className="h1 text-center">Contact</h1>
        </div>
      </section>
      <section className="py-20">
        <div className="container--boxed-sm prose dark:prose-invert">
          <p>
            Andrew Ng is affiliated with a number of institutions so please read
            the following carefully to determine the best way to contact him.
          </p>
          <p>
            <strong>Landing AI:</strong> If you have any business, partnership
            or press inquiries regarding Landing AI, or would like to learn more
            about AI solutions for enterprise environments, please visit our{" "}
            <a href="https://landing.ai/contact-us/" target="_blank">
              contact page
            </a>{" "}
            or email <a href="mailto:hello@landing.ai">hello@landing.ai</a>.
          </p>
          <p>
            <strong>AI Fund:</strong> If you are interested in investing in AI
            Fund or have a question about AI Fund, please visit our{" "}
            <a href="https://aifund.ai/say-hello/" target="_blank">
              contact page
            </a>{" "}
            or email <a href="mailto:hello@aifund.ai">hello@aifund.ai</a>.
          </p>
          <p>
            For all other inquiries (speaking requests, current Stanford
            students, <a href="https://www.deeplearning.ai/">DeepLearning.AI</a> related, feedback on online courses,
            etc.), please use the following form so that your request is sent to
            the appropriate parties.
          </p>
        </div>
        <div className="container--boxed-sm mt-10">
          <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLSffW4eFACIPXusfCCnGWr7aqN83mJbUcU2roevuehaadcVyTg/viewform?embedded=true"
            width="100%"
            height="700"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
            className="border rounded-md"
          >
            Loading…
          </iframe>
          <div className="mt-3">
            <a
              className="text-sm font-medium hover:underline text-slate-400 hover:text-slate-600 inline-flex underline-offset-1"
              href="https://docs.google.com/forms/d/e/1FAIpQLSffW4eFACIPXusfCCnGWr7aqN83mJbUcU2roevuehaadcVyTg/viewform"
            >
              View this form in new tab?{" "}
              <FiArrowUpRight className="text-lg ml-1" />
            </a>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default ContactPage

export const pageQuery = graphql`
  query WordPressContactPage {
    pageMeta: wpPage(slug: { eq: "contact" }) {
      ...WpPageFields
    }
  }
`
